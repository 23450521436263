import { Card, Divider, Switch } from '@blueprintjs/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState, useTypedDispatch } from '../../../stores';
import { setDarkMode } from '../../../stores/ui';
import SidebarArrowButton from '../../elements/SidebarArrowButton';
import SidebarButton from '../../elements/SidebarButton';
import styles from './sidebar.module.css';

interface SidebarProps {
  collapsed?: boolean,
  onCollapse: () => void,
}

const Sidebar: React.FC<SidebarProps> = ({ collapsed, onCollapse }) => {
  const dispatch = useTypedDispatch();
  const { dark } = useSelector((s: RootState) => s.ui);
  const { user } = useSelector((s: RootState) => s.user);

  return (
    <Card className={styles.root} elevation={1}>
      <div className={styles.arrow}>
        <SidebarArrowButton
          icon={collapsed ? 'double-chevron-right' : undefined}
          rightIcon={!collapsed ? 'double-chevron-left' : undefined}
          onClick={onCollapse}
          ali={!collapsed ? 'right' : 'right'}
        >
          {!collapsed && 'Navigation'}&nbsp;
        </SidebarArrowButton>

        <Divider style={{ margin: 0 }} />
      </div>
      <div className={styles.menu}>
        <SidebarButton collapse={collapsed} href="#/dashboard" icon="dashboard">
          Dashboard
        </SidebarButton>
        {
          user.roles != null && user.roles.canManageBerkas > 0 && (
            <SidebarButton collapse={collapsed} href="#/berkas" icon="projects">
              Berkas
            </SidebarButton>
        )}
        {
          user.roles != null && user.roles.canManagePlotting > 0 && (
            <SidebarButton collapse={collapsed} href="#/plotting" icon="map">
              Plotting
            </SidebarButton>
        )}
        {
          user.roles != null && user.roles.canManagePengesahan > 0 && (
            <SidebarButton 
              collapse={collapsed} href={`${
                user.roleId === 7 ? "#/pengesahan/kabid" :  
                user.roleId === 8 ? "#/pengesahan/sekdis" :
                user.roleId === 9 ? "#/pengesahan/kadis" : "#/pengesahan" 
              }`} icon="saved">
              Pengesahan
            </SidebarButton>
        )}
        {/* {
          user.roles != null && user.roles.canManageRetribusi > 0 && (
            <SidebarButton collapse={collapsed} href="#/retribusi" icon="credit-card">
              Retribusi
            </SidebarButton>
        )} */}
        <SidebarButton collapse={collapsed} href="#/reports" icon="timeline-line-chart">
          Reports
        </SidebarButton>
        {
          user.roles != null && user.roles.canManageUser > 0 && (
            <>        
            <SidebarButton collapse={collapsed} href="#/config" icon="cog">
              Configuration
            </SidebarButton>
            <SidebarButton collapse={collapsed} href="#/users" icon="people">
              User Management
            </SidebarButton>
            </>
          )
        }
      </div>

      <div className={styles.footer}>
        <Divider style={{ margin: '0 -16px 16px' }} />

        <Switch
          alignIndicator={!collapsed ? 'right' : undefined}
          checked={dark}
          label={!collapsed ? 'Dark mode' : undefined}
          onChange={() => dispatch(setDarkMode(!dark))}
        />
      </div>
    </Card>
  );
};

export default Sidebar;
