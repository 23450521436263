import axios, { AxiosResponse } from 'axios';
import { getBasicHeader, getBearerHeader } from '../utils/services';
import { BASE_URL } from './url';

function getAll(token: string): Promise<AxiosResponse> {
  const url = `${BASE_URL}/users`;
  return axios.get(url, { headers: getBearerHeader(token) });
}

function getByID(token: string, id: number): Promise<AxiosResponse> {
  const url = `${BASE_URL}/users/${id}`;
  return axios.get(url, { headers: getBearerHeader(token) });
}

function getByClinetID(token: string, id: number): Promise<AxiosResponse> {
  const url = `${BASE_URL}/users/${id}/get-by-client-id`;
  return axios.get(url, { headers: getBearerHeader(token) });
}

function getByPlotting(token: string): Promise<AxiosResponse> {
  const url = `${BASE_URL}/users/suggest/role/3`;
  return axios.get(url, { headers: getBearerHeader(token) });
}

function getSuggest(token: string): Promise<AxiosResponse> {
  const url = `${BASE_URL}/users/suggest`;
  return axios.get(url, { headers: getBearerHeader(token) });
}

function insert(token: string, form: UserForm): Promise<AxiosResponse> {
  const url = `${BASE_URL}/users`;
  const data = {
    roleId:form.roleId,
    name: form.name,
    email:form.email,
    password: form.password,
    salt: '-',
  };

  return axios.post(url, data, { headers: getBearerHeader(token) });
}

function update(token: string, form: UserForm): Promise<AxiosResponse> {
  const url = `${BASE_URL}/users/${form.id}`;
  const formData = new FormData();
  formData.append('_method', 'PUT');
  formData.append('roleId', JSON.stringify(form.roleId));
  formData.append('name', form.name);
  formData.append('email', form.email);

  return axios.post(url, formData, { headers: getBearerHeader(token) });
}

function updatePassword(token: string, form: UserForm): Promise<AxiosResponse> {
  const url = `${BASE_URL}/users/${form.id}/password`;
  const formData = new FormData();
  formData.append('_method', 'PUT');
  formData.append('password', form.password);
  formData.append('retype', form.retype);

  return axios.post(url, formData, { headers: getBearerHeader(token) });
}

function remove(token: string, id: number): Promise<AxiosResponse> {
  const url = `${BASE_URL}/users/${id}`;
  return axios.delete(url, { headers: getBearerHeader(token) });
}

function login(email: string, password: string): Promise<AxiosResponse> {
  const url = `${BASE_URL}/login`
  const token = window.btoa(`${email}:${password}`);
  return axios.post(url, {email, password}, { headers: getBasicHeader(token) });
}

const UserService = {
  getAll,
  getByID,
  getByClinetID,
  getSuggest,
  getByPlotting,
  insert,
  update,
  updatePassword,
  remove,
  login,
};

export default UserService;