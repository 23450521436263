import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import LoadingPage from '../components/layouts/LoadingPage';
import MainBase from '../components/layouts/MainBase';
import MainBaseUser from '../components/userLayouts/MainBase';
import { RootState } from '../stores';

const load = (Component: any) => (props: any) => (
  <Suspense fallback={<LoadingPage />}>
    <Component {...props} />
  </Suspense>
);

//Admin Page 
const BayarManagement = load(lazy(() => import('../pages/BayarManagement')));
const BayarModify = load(lazy(() => import('../pages/BayarModify')));
const BayarNew = load(lazy(() => import('../pages/BayarNew')));
const Berkas = load(lazy(() => import('../pages/Berkas')));
const BerkasDetail = load(lazy(() => import('../pages/BerkasDetail')));
const BerkasFinal = load(lazy(() => import('../pages/BerkasFinal')));
const BerkasFinalDetail = load(lazy(() => import('../pages/BerkasFinalDetail')));
const Dashboard = load(lazy(() => import('../pages/Dashboard')));
const ForgetPassword = load(lazy(() => import('../pages/ForgetPassword')));
const Login = load(lazy(() => import('../pages/Login')));
const NotFound = load(lazy(() => import('../pages/NotFound')));
const Pengesahan = load(lazy(() => import('../pages/Pengesahan')));
const PengesahanDetail = load(lazy(() => import('../pages/PengesahanDetail')));
const PengesahanKabid = load(lazy(() => import('../pages/PengesahanKabid')));
const PengesahanKabidDetail = load(lazy(() => import('../pages/PengesahanKabidDetail')));
const PengesahanKadis = load(lazy(() => import('../pages/PengesahanKadis')));
const PengesahanKadisDetail = load(lazy(() => import('../pages/PengesahanKadisDetail')));
const PengesahanSekdis = load(lazy(() => import('../pages/PengesahanSekdis')));
const PengesahanSekdisDetail = load(lazy(() => import('../pages/PengesahanSekdisDetail')));
const Plotting = load(lazy(() => import('../pages/Plotting')));
const PlottingDetail = load(lazy(() => import('../pages/PlottingDetail')));
const PlottingDone = load(lazy(() => import('../pages/PlottingDone')));
const PlottingDoneDetail = load(lazy(() => import('../pages/PlottingDoneDetail')));
const Register = load(lazy(() => import('../pages/Register')));
const Reports = load(lazy(() => import('../pages/Reports')));
const ResetPassword = load(lazy(() => import('../pages/ResetPassword')));
const Retribusi = load(lazy(() => import('../pages/Retribusi')));
const RetribusiDetail = load(lazy(() => import('../pages/RetribusiDetail')));
const Sliders = load(lazy(() => import('../pages/Sliders')));
const UserManagement = load(lazy(()=> import('../pages/UserManagement')));
const UserModify = load(lazy(()=> import('../pages/UserModify')));
const UserNew = load(lazy(()=> import('../pages/UserNew')));
const UserRoles = load(lazy(()=> import('../pages/UserRoles')));
const UserRoleModify = load(lazy(()=> import('../pages/UserRoleModify')));
const UserRoleNew = load(lazy(()=> import('../pages/UserRoleNew')));

//Client Page
const BantuanCilent = load(lazy(() => import('../pages/ClientPage/Bantuan')));
const DashboardClient = load(lazy(() => import('../pages/ClientPage/Dashboard')));
const DownloadForm = load(lazy(() => import('../pages/ClientPage/DownloadForm')));
const Pembayaran = load(lazy(() => import('../pages/ClientPage/Pembayaran')));
const Permohonan = load(lazy(() => import('../pages/ClientPage/Permohonan')));
const PermohonanModify = load(lazy(() => import('../pages/ClientPage/PermohonanModify')));
const Tinjauan = load(lazy(() => import('../pages/ClientPage/Tinjauan')));

//Landing Page
const Bantuan = load(lazy(() => import('../pages/LandingPage/Bantuan')));
const CheckData = load(lazy(() => import('../pages/LandingPage/CheckData')));
const LandingPageDashboard = load(lazy(() => import('../pages/LandingPage/Dashboard')));
const Peta = load(lazy(() => import('../pages/LandingPage/Peta')));
const Tutorial = load(lazy(() => import('../pages/LandingPage/Tutorial')));
const TutorialVideo = load(lazy(() => import('../pages/LandingPage/TutorialVideo')));

const ProtectedRoutes: React.FC<{ user: User }> = ({ user }) => {
  const location = useLocation();

  if(user.id === -1 && location.pathname !== '/login'){
    return <Navigate replace to="/login" />;
  }

  if(user.roleId > 0){
    return (
      <MainBase>
        <Routes>
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path="/berkas" element={<Berkas />} />
          <Route path="/berkas/plotting" element={<PlottingDone />} />
          <Route path="/berkas/plotting/:id" element={<PlottingDoneDetail />} />
          <Route path="/berkas/final" element={<BerkasFinal />} />
          <Route path="/berkas/final/:id" element={<BerkasFinalDetail />} />
          <Route path="/berkas/:id" element={<BerkasDetail />} />
          <Route path="/plotting" element={<Plotting />} />
          <Route path="/plotting/:id" element={<PlottingDetail />} />
          <Route path="/pengesahan" element={<Pengesahan />} />
          <Route path="/pengesahan/kabid" element={<PengesahanKabid />} />
          <Route path="/pengesahan/kabid/:id" element={<PengesahanKabidDetail />} />
          <Route path="/pengesahan/sekdis" element={<PengesahanSekdis />} />
          <Route path="/pengesahan/sekdis/:id" element={<PengesahanSekdisDetail />} />
          <Route path="/pengesahan/kadis" element={<PengesahanKadis />} />
          <Route path="/pengesahan/kadis/:id" element={<PengesahanKadisDetail />} />
          <Route path="/pengesahan/:id" element={<PengesahanDetail />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/retribusi" element={<Retribusi />} />
          <Route path="/retribusi/:id" element={<RetribusiDetail />} />
          <Route path="/config" element={<Sliders />} />
          <Route path="/config/retribusi" element={<BayarManagement />} />
          <Route path="/config/retibusi/new" element={<BayarNew />} />
          <Route path="/config/retibusi/:id" element={<BayarModify />} />
          <Route path="/users" element={<UserManagement />} />
          <Route path="/users/new" element={<UserNew />} />
          <Route path="/users/roles" element={<UserRoles />} />
          <Route path="/users/roles/new" element={<UserRoleNew />} />
          <Route path="/users/roles/:id" element={<UserRoleModify />} />
          <Route path="/users/:id" element={<UserModify />} />
          <Route path="*" element={<NotFound />}/>
        </Routes>
      </MainBase>
    );
  }

  return (
    <MainBaseUser>
      <Routes>
        <Route path="/dashboard" element={<DashboardClient />} />
        <Route path="/permohonan" element={<Permohonan />} />
        <Route path="/permohonan/:id" element={<PermohonanModify />} />
        <Route path="/tinjauan" element={<Tinjauan />} />
        <Route path="/pembayaran" element={<Pembayaran />} />
        <Route path="/bantuan-page" element={<BantuanCilent />} />
        <Route path="/form" element={<DownloadForm />} />
        <Route path="*" element={<NotFound />}/>
      </Routes>
    </MainBaseUser>
  );
};

const AppRoutes: React.FC = () =>  {
  const { user } = useSelector((s: RootState) => s.user);

  return (
    <Routes>
      <Route index element={<LandingPageDashboard />} />
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="forget" element={<ForgetPassword />} />
      <Route path="password/reset/:string" element={<ResetPassword />} />
      <Route path="/public-peta" element={<Peta />} />
      <Route path="/checkdata/:string" element={<CheckData />}/>
      <Route path="/tutorial" element={<Tutorial />} />
      <Route path="/tutorial/video" element={<TutorialVideo />} />
      <Route path="/bantuan" element={<Bantuan />} />
      <Route path="*" element={<ProtectedRoutes user={user} />} />
    </Routes>
)};

export default AppRoutes;
