import { NavigateFunction } from 'react-router-dom';
import { Form } from '../../components/forms/LoginForm';
import UserService from '../../services/user';
import { saveUser } from '../../utils/storage';
import { setLoading, setMessage } from '../ui';
import { AppThunk } from '..';
import { setUser } from '.';

export function login(navigate: NavigateFunction, form: Form): AppThunk {
  const { email, password, remember } = form;

  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await UserService.login(email, password);
      if(Object.values(data.data).length > 0){
        const initialData:User = {
          ...data.data,
          authorization: data.authorization,
        }

        if (remember) {
          saveUser(initialData);
        }
        
        dispatch(setUser(initialData));
        dispatch(setLoading(false));
        navigate('/dashboard');
      }
      
    } catch (err: any) {
      if (err.response != null && err.response.data != null && err.response.data.message != null) {
        dispatch(setMessage({ message: err.response.data.message, intent: 'danger' }));
      } else {
        dispatch(setMessage({ message: err.message, intent: 'danger' }));
      }
      dispatch(setLoading(false));
    }
  };
}