import React from 'react';
import { AnchorButton, Card } from '@blueprintjs/core';
import whatsapp from '../../../assets/whatsapp.png';
import { WA_URL } from '../../../services/url';
import styles from './bubblechat.module.css';

interface Props {
}

const BubbleChat: React.FC<Props> = () => (
  <div className={styles.root}>
    <Card className={styles.card}>
      <AnchorButton 
        fill 
        className={styles.button} 
        minimal
        href={WA_URL}
        target="_blank"
      >
        <img className={styles.img} src={whatsapp} alt="pemerintah kabupaten tanggerang" />
      </AnchorButton>
    </Card>
  </div>
);

export default BubbleChat;
 