import { Spinner } from '@blueprintjs/core';
import React from 'react';
import styles from './loadingpage.module.css';

interface Props{
  isTitleHide?: boolean,
}

const LoadingPage: React.FC<Props> = ({isTitleHide}) => (
  <div className={styles.root}>
    <Spinner intent="primary" size={64} />
    {(isTitleHide === false || isTitleHide === undefined) && <p>Loading Page..</p>}
  </div>
);

export default LoadingPage;
