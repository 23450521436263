// export const BASE_URL = `http://${window.location.hostname}:8000/api`;
// export const IMAGE_URL = `http://${window.location.hostname}:8000/storage`;
// export const RESET_PASSWORD_URL = `http://${window.location.hostname}:3000/#`;
// export const QR_CODE_URL = `http://${window.location.hostname}:3000/#`;
// export const WA_URL  = `https://wa.me/6281288586283`;
// export const GOOGLE_EARTH_URL = 'https://earth.google.com/web/search/kab.tangerang';

//deploy
// export const BASE_URL = `http://103.175.218.246:9005/api`;
// export const IMAGE_URL = `http://103.175.218.246:9005/storage`;
// export const RESET_PASSWORD_URL = `http://103.175.218.246:4005/#`;
// export const QR_CODE_URL = `http://103.175.218.246:4005/#`;
// export const WA_URL  = `https://wa.me/6281288586283`;
// export const GOOGLE_EARTH_URL = 'https://earth.google.com/web/search/kab.tangerang';

//deploy server DEV
const getProtocol = (): string => {
  const protocol = window.location.protocol;
  if(protocol === 'http:' || protocol === 'https:'){
    return window.location.protocol;
  }

  return 'https:';
}

export const BASE_URL = `${getProtocol()}//sipetarung.devapp.my.id/api`;
export const IMAGE_URL = `${getProtocol()}//sipetarung.devapp.my.id/storage`;
export const RESET_PASSWORD_URL = `${getProtocol()}//sipetarung.devapp.my.id/#`;
export const QR_CODE_URL = `${getProtocol()}//sipetarung.devapp.my.id/#`;
export const WA_URL  = `https://wa.me/6281288586283`;
export const GOOGLE_EARTH_URL = 'https://earth.google.com/web/search/kab.tangerang';

//production
// const getProtocol = (): string => {
//   const protocol = window.location.protocol;
//   if(protocol === 'http:' || protocol === 'https:'){
//     return window.location.protocol;
//   }

//   return 'https:';
// }

// export const BASE_URL = `${getProtocol()}//sipetarung.tangerangkab.go.id/api`;
// export const IMAGE_URL = `${getProtocol()}//sipetarung.tangerangkab.go.id/storage`;
// export const RESET_PASSWORD_URL = `${getProtocol()}//sipetarung.tangerangkab.go.id/#`;
// export const QR_CODE_URL = `${getProtocol()}//sipetarung.tangerangkab.go.id/#`;
// export const WA_URL  = `https://wa.me/6281288586283`;
// export const GOOGLE_EARTH_URL = 'https://earth.google.com/web/search/kab.tangerang';