import { Card, Divider, Switch } from '@blueprintjs/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState, useTypedDispatch } from '../../../stores';
import { setDarkMode } from '../../../stores/ui';
import SidebarArrowButton from '../../elements/SidebarArrowButton';
import SidebarButton from '../../elements/SidebarButton';
import styles from './sidebar.module.css';

interface SidebarProps {
  collapsed?: boolean,
  onCollapse: () => void,
}

const Sidebar: React.FC<SidebarProps> = ({ collapsed, onCollapse }) => {
  const dispatch = useTypedDispatch();
  const { dark } = useSelector((s: RootState) => s.ui);

  return (
    <Card className={styles.root} elevation={1}>
      <div className={styles.arrow}>
        <SidebarArrowButton
          icon={collapsed ? 'double-chevron-right' : undefined}
          rightIcon={!collapsed ? 'double-chevron-left' : undefined}
          onClick={onCollapse}
          ali={!collapsed ? 'right' : 'right'}
        >
          {!collapsed && 'Navigation'}&nbsp;
        </SidebarArrowButton>

        <Divider style={{ margin: 0 }} />
      </div>
      <div className={styles.menu}>
        <SidebarButton collapse={collapsed} href="#/dashboard" icon="home">
          Beranda
        </SidebarButton>
        <SidebarButton collapse={collapsed} href="#/permohonan" icon="document">
          Permohonan
        </SidebarButton>
        <SidebarButton collapse={collapsed} href="#/tinjauan" icon="shopping-cart">
          Tinjau Pengajuan
        </SidebarButton>
        {/* <SidebarButton collapse={collapsed} href="#/pembayaran" icon="credit-card">
          Pembayaran
        </SidebarButton> */}
        <SidebarButton collapse={collapsed} href="#/form" icon="application">
          Download Form
        </SidebarButton>
        <SidebarButton collapse={collapsed} href="#/bantuan-page" icon="headset">
          Bantuan
        </SidebarButton>
      </div>

      <div className={styles.footer}>
        <Divider style={{ margin: '0 -16px 16px' }} />

        <Switch
          alignIndicator={!collapsed ? 'right' : undefined}
          checked={dark}
          label={!collapsed ? 'Dark mode' : undefined}
          onChange={() => dispatch(setDarkMode(!dark))}
        />
      </div>
    </Card>
  );
};

export default Sidebar;
