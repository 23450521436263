import { Button, Menu, MenuItem, Navbar, H3 } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import logo from '../../../assets/logo.png';
import logoWhite from '../../../assets/logo.png';
import logoOnly from '../../../assets/logo.png';
import { RootState, useTypedDispatch } from '../../../stores';
import { setUser } from '../../../stores/user';
import { clearLocalStorage } from '../../../utils/storage';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import HeaderContext from './HeaderContext';
import BubbleChat from '../../../components/elements/BubbleChat';
import styles from './header.module.css';

interface Props {
  className?: string,
  collapse?: boolean,
}

const Header: React.FC<Props> = ({ className, collapse }) => {
  const { content } = useContext(HeaderContext);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { dark } = useSelector((s: RootState) => s.ui);
  const { name } = useSelector((s: RootState) => s.user).user;
  const { width } = useWindowDimensions();

  const logout = useCallback(() => {
    dispatch(setUser({ 
      id: -1,
      roleId: -1,
      roles: null,
      email: '',
      name: '',
      kategori: null,
      authorization: {
        token: null,
        type: null,
      },
    }));
    clearLocalStorage();
    navigate('/login');
  }, [dispatch, navigate]);

  return (
    <div className={className}>
      <Navbar>
        {
          (width > 767 || (width <= 767 && content == null)) &&
          <Navbar.Group className={collapse ? styles.collapse : styles.expand}>
            <div className={styles.newlogo}>
              <img
                // className={styles.logo}
                // height={40}
                src={dark ? logoWhite : logo}
                alt="Pemerintahan Kabupaten Tangerang"
              />
            </div>
            {collapse === false  && <H3>SIPETARUNG</H3>}
          </Navbar.Group>
        }

        <Navbar.Group>
          {content}
        </Navbar.Group>

        { 
          width > 767 &&
          <>
            <Navbar.Group align="right">
              <Popover2
                className={styles.desktop}
                content={
                  <Menu>
                    <MenuItem icon="log-out" text="Log out" onClick={logout} />
                  </Menu>
                }
                interactionKind="click"
                placement="bottom-end"
                renderTarget={({ isOpen, ref, ...targetProps }) => (
                  <Button {...targetProps} minimal elementRef={ref!} icon="user" text={name} />
                )}
              />
            </Navbar.Group>
          </>
        }
      </Navbar>
      <BubbleChat />
    </div>
  );
};

export default Header;
