import { encodeBase64, decodeBase64 } from './base64';

const PREFIX = 'sipetarung';

const SIDEBAR = `${PREFIX}.sidebar`;
const USER = `${PREFIX}.user`;
const CLIENT = `${PREFIX}.client`;

export function clearLocalStorage(): void {
  localStorage.removeItem(USER);
  localStorage.removeItem(SIDEBAR);
}

export function getUser(): User | null {
  const savedUser = localStorage.getItem(USER);
  if (savedUser == null) {
    return null;
  }

  try {
    return JSON.parse(atob(savedUser));
  } catch {
    return null;
  }
}

export function saveUser(user: User): void {
  const serializeUser = JSON.stringify(user);
  localStorage.setItem(USER, btoa(serializeUser));
}

export function getSidebar(): SidebarState | null {
  const state = localStorage.getItem(SIDEBAR);
  if (state !== 'expand' && state !== 'collapse') {
    return null;
  }

  return state;
}

export function saveSidebar(state: SidebarState): void {
  localStorage.setItem(CLIENT, state);
}

export function getClientData(): Client | null {
  const saveClient = localStorage.getItem(CLIENT);
  if (saveClient == null) {
    return null;
  }

  try {
    return JSON.parse(decodeBase64(saveClient));
  } catch {
    return null;
  }
}

export function saveClientData(client: Client): void {
  const serializeClient = JSON.stringify(client);
  localStorage.setItem(CLIENT, encodeBase64(serializeClient));
}
